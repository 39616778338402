<template>
  <div class="register app_content">
    <div class="warp_body">
      <div class="registerBody">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
                v-model="form.workTypeText"
                is-link
                readonly
                required
                name="picker"
                label="工种"
                placeholder="点击选择工种"
                @click="getWorkTypeList"
                :rules="[{ validator: validatorWorkTypeMessage }]"
            />
            <van-popup v-model:show="showWorkTypePicker" position="bottom">
              <van-picker
                  :columns="workTypeList"
                  @confirm="onConfirmWorkType"
                  @cancel="showWorkTypePicker = false"
              />
            </van-popup>

            <van-field
                v-model="form.levelText"
                is-link
                readonly
                required
                name="picker"
                label="级别"
                placeholder="点击选择级别"
                @click="getLevelList"
                :rules="[{ validator: validatorLevelMessage }]"
            />
            <van-popup v-model:show="showLevelPicker" position="bottom">
              <van-picker
                  :columns="levelList"
                  @confirm="onConfirmLevel"
                  @cancel="showLevelPicker = false"
              />
            </van-popup>


            <van-field v-model="form.price" required label="服务价格区间" type="digit" label-width="100px"/>

            <van-field name="personalPic" label="个人照片上传" required label-width="100px"
                       :rules="[{ validator: validatorPersonImgMessage }]">
              <template #input>
                <van-uploader v-model="fileListPerson" max-count=1 :before-delete="beforeDeletePerson"
                              :after-read="afterReadPersonImg"/>
              </template>
            </van-field>

            <van-field name="certificationPic" label="工人资质上传" label-width="100px" required
                       :rules="[{ validator: validatorWorkPermitImgMessage }]">
              <template #input>
                <van-uploader max-count=1 v-model="fileListWorkPermit" :before-delete="beforeDeleteWorkPermit"
                              :after-read="afterReadWorkPermitImg"/>
              </template>
            </van-field>


            <van-field label="接单状态" readonly required>
              <template #right-icon>
                <van-switch v-model="form.orderStatus" size="24"/>
              </template>
            </van-field>

            <van-field v-model="form.companyName" label-width="95px" label="所属单位名称" placeholder="请输入所属单位名称"
            />
            <van-field v-model="form.companyCreditCode" label-width="95px" label="单位信用代码" placeholder="请输入单位信用代码"
            />
          </van-cell-group>

          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import req from "../request/request";
import API from "../api/User";
import router from "../router";
import {Toast} from "vant";
import {compress} from 'image-conversion'

export default {
  name: 'CompleteWorker',
  setup() {

    onMounted(() => {
    })


    const data = reactive({
          showIdCard: false,
          verificationCodeButton: {content: '获取验证码', disabled: false},
          serviceList: [{name: "固定工种", value: 1,}, {name: "非固定工种", value: 0,}],
          fileListPerson: [],
          fileListWorkPermit: [],
          showRegisterDetailItem: false,
          showAreaPicker: false,
          showWorkTypePicker: false,
          showLevelPicker: false,
          servceTypeCheck: '',
          areaList: [],
          workTypeList: [],
          levelList: [],
          form: {
            areaText: '',
            levelId: '',
            level: '',
            levelText: '',
            price: '',
            workTypeId: '',
            workType: '',
            serviceType: '',
            workTypeText: '',
            personImg: '',
            workPermitImg: '',
            companyName: '',
            companyCreditCode: '',
            orderStatus: true,
          },
          inputErrorText: {
            workType: '',
            level: '',
            price: '',
            personImg: '',
            workPermitImg: '',
          },

        },
    )
    // // 校验函数返回 true 表示校验通过，false 表示不通过
    // const validator = (val) => /1\d{10}/.test(val);
    //
    // // 校验函数可以直接返回一段错误提示
    // const validatorMessage = (val) => `${val} 不合法，请重新输入`;

    const methodsMap = {
      selectServiceType: () => {
        if (data.form.serviceType == 0) {
          data.showRegisterDetailItem = false;
          data.form.workTypeId = '';
          data.form.level = '';
          data.form.price = '';
          data.form.personImg = '';
          data.form.workPermitImg = '';
          data.form.orderStatus = '';
          data.form.companyCreditCode = '';
          data.form.companyName = '';
        } else if (data.form.serviceType == 1) {
          data.showRegisterDetailItem = true;
        }

      },
      validatorPhonePattern: (val) => {
        return /^(1\d{10})$/.test(val)
      },
      validatorCodeMessage: (val) => {
        if (!val) {
          return '请输入验证码'
        }
      },
      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      validatorWorkTypeMessage: (val) => {
        if (!val) {
          return '请选择工种'
        }
      },
      validatorLevelMessage: (val) => {
        if (!val) {
          return '请选择级别'
        }
      }, validatorPersonImgMessage: () => {
        if (data.fileListPerson.length == 0) {
          return '请上传个人照片'
        }
      }, validatorWorkPermitImgMessage: () => {
        if (data.fileListWorkPermit.length == 0) {
          return '请上传资质凭证'
        }
      },
      onSubmit: () => {
        // let form = data.form
        // console.log('submit', form)
        // if (!form.name) {
        //   data.inputErrorText.name = '请输入姓名'
        // }
        // if (!form.idCard.match(/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)) {
        //   data.inputErrorText.idCard = '请输入正确的身份证明号码'
        // }
        // if (!form.idCard) {
        //   data.inputErrorText.idCard = '请输入身份证明号码'
        // }
        // if (!form.area) {
        //   data.inputErrorText.area = '请选择所在区域'
        // }
        // if (!form.workType) {
        //   data.inputErrorText.workType = '请选择工种'
        // }
        // if (!form.level) {
        //   data.inputErrorText.level = '请选择级别'
        // }
        //
        // if (!form.phone.match('/^(1\\d{10})$/')) {
        //   data.inputErrorText.phone = '手机号码不合法，请输入正确的手机号码'
        // }
        // if (!form.phone) {
        //   data.inputErrorText.phone = '请输入手机号码'
        // }
        // if (data.fileListPerson.length == 0) {
        //   data.inputErrorText.personImg = '请上传个人照片'
        // }
        // if (data.fileListWorkPermit.length == 0) {
        //   data.inputErrorText.workPermitImg = '请上传工作资质照片'
        // }
        let params = data.form
        // params.personImg = data.fileListPerson[0].file
        // params.workPermitImg = data.fileListWorkPermit[0].file
        req.request(API.completeWorker, params,
            {method: 'POST_FORMDATA'}, {message: '注册中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/')
                Toast.success("提交成功！")
              }
            })

      },
      afterReadPersonImg: (file) => {
        console.log(file.file)
        console.log(data.fileListPerson[0].file)

        new Promise((resolve) => {
          compress(file.file, 0.6)
              .then(res => {
                console.log(res)
                data.form.personImg = new window.File([res], file.file.name, {type: file.file.type})
                // new window.File([resolve(res),file.file.name,{type:file.file.type}])
              });
        })
        // data.form.personImg = new window.File([compress(file.file,0.5),file.file.name,{type:file.file.type}])
      },
      afterReadWorkPermitImg: (file) => {
        console.log('sss', file.file)
        console.log('sss', data.fileListWorkPermit[0].file)
        // data.form.workPermitImg = compress(data.fileListWorkPermit[0].file, 0.5)
        new Promise((resolve) => {
          compress(file.file, 0.6)
              .then(res => {
                console.log(res)
                data.form.workPermitImg = new window.File([res], file.file.name, {type: file.file.type})
                // new window.File([resolve(res),file.file.name,{type:file.file.type}])
              });
        })
      },
      beforeDeletePerson: () => {
        data.fileListPerson = []
        data.form.personImg = ''
      },
      beforeDeleteWorkPermit: () => {
        data.fileListWorkPermit = []
        data.form.workPermitImg = ''
      },
      getLevelList: () => {
        data.showLevelPicker = true
        req.request(API.levelList, null, {method: 'GET'})
            .then((res) => {
              data.levelList = res.data.data
            })
      },
      getWorkTypeList: () => {
        data.showWorkTypePicker = true
        req.request(API.workTypeList, null, {method: 'GET'})
            .then((res) => {
              data.workTypeList = res.data.data
            })
      },
      onConfirmWorkType: (item) => {
        data.showWorkTypePicker = false
        data.form.workTypeText = item.text
        data.form.workType = item.value
        data.form.workTypeId = item.id
      },
      onConfirmLevel: (item) => {
        data.showLevelPicker = false
        data.form.levelText = item.text
        data.form.level = item.value
        data.form.price = item.price
        data.form.levelId = item.id
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.app_content {
  .registerBody {
    margin-top: 5px;
    --van-number-keyboard-title-font-size: 10px;
  }
}
</style>
